import axios from 'axios';
import { useEffect, useState } from 'react';
import { config } from '../../../config';
import { ALREADY_UNSUBSCRIBED, CONFIRM_UNSUBSCRIPTION, LINK_EXPIRED, PAGE_NOT_FOUND, SUCCESS } from '../routes/routes';
import useCommonData from './useCommonData';
import jwt_decode from 'jwt-decode';
import { useAppSelector } from '../../../configuration/setup/hooks';
import { getToken } from '../appSlice';

type TokenInfo = {
    language: string;
};

const useUnsubscription = (setLocale?: (value: string) => void) => {
    const { setNavigation, dispatchLoadingChanged, location, unsubscribedInfo, setUnsubscribedInfo } = useCommonData();
    const [confirmUnsubscription, setConfirmUnsubscription] = useState<boolean>(false);
    const token = useAppSelector(getToken);

    const unsubscribe = () => {
        axios
            .get(config.backend.NOTIFICATION_SERVICE + '/unsubscribe?token=' + token)
            .then((res) => {
                setUnsubscribedInfo({
                    language: res.data.language,
                    email_type: res.data.email_type,
                    audience: res.data.audience,
                });
                if (setLocale) setLocale(res.data.language);
                if (res.status === 201) setNavigation(SUCCESS);
                else setNavigation(ALREADY_UNSUBSCRIBED);
            })
            .catch((error) => {
                if (error.response?.status === 404) setNavigation(LINK_EXPIRED);
                else setNavigation(PAGE_NOT_FOUND);
            });
    };

    useEffect(() => {
        if (!location.isLoading && confirmUnsubscription) {
            dispatchLoadingChanged(true);
            unsubscribe();
        } else {
            setNavigation(CONFIRM_UNSUBSCRIPTION);
            if (token) {
                const { language } = jwt_decode(token) as TokenInfo;
                if (setLocale) setLocale(language);
            }
        }
    }, [token, confirmUnsubscription]);

    return { unsubscribedInfo, route: location.route, setConfirmUnsubscription };
};

export default useUnsubscription;
