import axios from 'axios';
import { useEffect, useState } from 'react';
import { config } from '../../../config';
import { LINK_EXPIRED, PAGE_NOT_FOUND, RESUBSCRIBED } from '../routes/routes';
import useCommonData from './useCommonData';

const useResubscription = (token?: string) => {
    const { setNavigation, dispatchLoadingChanged, location, unsubscribedInfo, setUnsubscribedInfo } = useCommonData();
    const [triggerReloadResubscription, setTriggerReloadResubscription] = useState<boolean>(false);

    useEffect(() => {
        if (!location.isLoading && triggerReloadResubscription) {
            setTriggerReloadResubscription(false);
            dispatchLoadingChanged(true);
            axios
                .get(config.backend.NOTIFICATION_SERVICE + '/resubscribe?token=' + token)
                .then((res) => {
                    setUnsubscribedInfo({
                        language: res.data.language,
                        email_type: res.data.email_type,
                        audience: res.data.audience,
                    });
                    if (res.status === 201 || res.status === 200) setNavigation(RESUBSCRIBED);
                })
                .catch((error) => {
                    if (error.response?.status === 404) setNavigation(LINK_EXPIRED);
                    else setNavigation(PAGE_NOT_FOUND);
                });
        }
    }, [token, triggerReloadResubscription]);

    return { unsubscribedInfo, route: location.route, setTriggerReloadResubscription };
};

export default useResubscription;
