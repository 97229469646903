import { IntlProvider } from 'react-intl';
import ApplicationLayout from '@rio-cloud/rio-uikit/lib/es/ApplicationLayout';
import { DEFAULT_LOCALE } from '../../configuration/lang/lang';
import { useAppSelector } from '../../configuration/setup/hooks';
import { getDisplayMessages, getLocale } from '../../configuration/lang/langSlice';
import AppHeader from './components/common/AppHeader';
import AppRoutes from './AppRoutes';
import useToken from './hooks/useToken';

const App = () => {
    const displayMessages = useAppSelector(getDisplayMessages);
    const language = useAppSelector(getLocale);
    const { token } = useToken();

    if (!displayMessages || !language) return null;

    // @ts-ignore
    return (
        <IntlProvider defaultLocale={DEFAULT_LOCALE} key={language} locale={language} messages={displayMessages}>
            <ApplicationLayout className="bg-white">
                <AppHeader />
                <ApplicationLayout.Body
                    innerClassName="padding-0 flex-0-1"
                    className="position-absolute height-auto bg-white"
                >
                    <AppRoutes token={token} />
                </ApplicationLayout.Body>
            </ApplicationLayout>
        </IntlProvider>
    );
};

export default App;
