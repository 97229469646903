import './polyfills';

import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';

import { main } from './configuration';
import { store } from './configuration/setup/store';
import { ErrorBoundary } from './features/app/ErrorBoundary';
import App from './features/app/App';

export const appComponents = (
    <ErrorBoundary>
        <Provider store={store}>
            <HashRouter>
                <App />
            </HashRouter>
        </Provider>
    </ErrorBoundary>
);
const renderApplication = () => {
    const root = document.getElementById('root');

    ReactDOM.render(appComponents, root);
};

main(renderApplication);
