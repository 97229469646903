import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../configuration/setup/hooks';
import { getLocation, loadingChanged, routeChanged } from '../../../configuration/setup/locationSlice';
import { UnsubscribedInfo } from '../../../generated/UnsubscribeServiceTypes';

const useCommonData = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const dispatchRouteChanged = (value: string) => dispatch(routeChanged(value));
    const dispatchLoadingChanged = (value: boolean) => dispatch(loadingChanged(value));
    const location = useAppSelector(getLocation);

    const [unsubscribedInfo, setUnsubscribedInfo] = useState<UnsubscribedInfo>({
        language: '',
        email_type: 'CUSTOMER_WEEKLY',
        audience: 'CUSTOMER',
    });

    const setNavigation = (path: string) => {
        dispatchRouteChanged(path);
        dispatchLoadingChanged(false);
        navigate('/' + path);
    };

    return { setNavigation, dispatchLoadingChanged, location, unsubscribedInfo, setUnsubscribedInfo };
};

export default useCommonData;
