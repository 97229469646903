import { FormattedMessage } from 'react-intl';

type EmailTypes =
    | 'CUSTOMER_FEEDBACK'
    | 'WORKSHOP_FEEDBACK'
    | 'CUSTOMER_WEEKLY'
    | 'WORKSHOP_WEEKLY'
    | 'WORKSHOP_EMAIL_ADDRESS_CONFIRMATION'
    | 'WORKSHOP_VEHICLES_REASSIGNMENT'
    | 'WORKSHOP_CANCELLATION'
    | 'CUSTOMER_APPOINTMENT_CONFIRMATION'
    | 'CUSTOMER_APPOINTMENT_REMINDER'
    | 'CUSTOMER_UPTIME_MISSED_CALL';

const SubHeader = ({ emailType }: { emailType: EmailTypes }) => {
    const isMobile = window.innerWidth <= 760;
    const isTablet = window.innerWidth <= 810;
    const manServiceCareMessage = 'notification-web.common.manServiceCare';

    const Title = () => (
        <FormattedMessage
            id={'notification-web.unsubscribed.title.1'}
            values={{
                successfully: (
                    <span style={{ color: '#E40045' }}>
                        <FormattedMessage id="notification-web.unsubscribed.title.2" />
                    </span>
                ),
            }}
        />
    );

    if (isMobile) {
        return (
            <div
                className="position-absolute text-color-white width-100pct padding-left-25"
                style={{ bottom: '5%', background: 'rgba(26, 34, 42, 0.5)' }}
            >
                <div className="width-100pct">
                    <h5>
                        <Title />
                    </h5>
                </div>
                <button className="btn btn-sm" style={{ backgroundColor: '#E40045' }}>
                    <b>
                        <FormattedMessage id={manServiceCareMessage} />
                    </b>
                </button>
            </div>
        );
    } else if (isTablet) {
        return (
            <div
                className="position-absolute text-color-white width-100pct padding-left-25"
                style={{ bottom: '5%', background: 'rgba(26, 34, 42, 0.5)' }}
            >
                <div className="width-100pct">
                    <h2>
                        <Title />
                    </h2>
                    <h6 className="margin-bottom-20">
                        <FormattedMessage
                            id={'notification-web.unsubscribed.subtitle'}
                            values={{
                                email: (
                                    <FormattedMessage id={`notification-web.email_type.${emailType.toLowerCase()}`} />
                                ),
                            }}
                        />
                    </h6>
                </div>
                <button className="btn " style={{ backgroundColor: '#E40045' }}>
                    <b>
                        <FormattedMessage id={manServiceCareMessage} />
                    </b>
                </button>
            </div>
        );
    } else
        return (
            <div className="position-absolute text-color-white width-100pct padding-left-25" style={{ bottom: '5%' }}>
                <h1>
                    <Title />
                </h1>
                <h5 className="margin-bottom-20">
                    <FormattedMessage
                        id={'notification-web.unsubscribed.subtitle'}
                        values={{
                            email: <FormattedMessage id={`notification-web.email_type.${emailType.toLowerCase()}`} />,
                        }}
                    />
                </h5>
                <button className="btn btn-lg" style={{ backgroundColor: '#E40045' }}>
                    <b>
                        <FormattedMessage id={manServiceCareMessage} />
                    </b>
                </button>
            </div>
        );
};

export default SubHeader;
