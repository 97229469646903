import { FormattedMessage } from 'react-intl';
import Footer from '../common/Footer';
import {
    ALREADY_UNSUBSCRIBED,
    CONFIRM_UNSUBSCRIPTION,
    FEEDBACK,
    LINK_EXPIRED,
    LOADING,
    PAGE_NOT_FOUND,
    RESUBSCRIBED,
    SUCCESS,
} from '../../routes/routes';
import PageNotFoundImage from '../../../../images/PageNotFound.png';
import CustomerUnsubscribedSuccessfullyImage from '../../../../images/CustomerUnsubscribedSuccessfully.png';
import WorkshopUnsubscribedSuccessfullyImage from '../../../../images/WorkshopUnsubscribedSuccessfully.png';
import LinkExpiredImage from '../../../../images/LinkExpired.png';
import FeedbackImage from '../../../../images/Feedback.png';
import ResubscribedSuccessfullyImage from '../../../../images/ResubscribedSuccessfully.png';
import { UnsubscribedInfo } from '../../../../generated/UnsubscribeServiceTypes';
import { useAppSelector } from '../../../../configuration/setup/hooks';
import { getLocation } from '../../../../configuration/setup/locationSlice';
import ContentLoader from '@rio-cloud/rio-uikit/lib/es/ContentLoader';
import useUnsubscription from '../../hooks/useUnsubscription';

const CommonPage = ({ unsubscribedInfo }: { unsubscribedInfo: UnsubscribedInfo }) => {
    const { audience, email_type } = unsubscribedInfo;
    const { route } = useAppSelector(getLocation);
    const isLoading = route === LOADING;
    const isFeedback = route === FEEDBACK;
    const { setConfirmUnsubscription } = useUnsubscription();

    const getImageSrc = () => {
        switch (route) {
            case ALREADY_UNSUBSCRIBED:
                return getAudienceImage();
            case LINK_EXPIRED:
            case CONFIRM_UNSUBSCRIPTION:
                return LinkExpiredImage;
            case PAGE_NOT_FOUND:
                return PageNotFoundImage;
            case RESUBSCRIBED:
                return ResubscribedSuccessfullyImage;
            case FEEDBACK:
                return FeedbackImage;
        }
    };

    const getAudienceImage = () => {
        switch (audience) {
            case 'CUSTOMER':
                return CustomerUnsubscribedSuccessfullyImage;
            case 'WORKSHOP':
                return WorkshopUnsubscribedSuccessfullyImage;
        }
    };

    const getImageAlt = () => {
        if (route === ALREADY_UNSUBSCRIBED) return `${audience} already unsubscribed`;
        else return route;
    };

    if (route === SUCCESS) return null;

    const goToUnsubscribePage = () => {
        setConfirmUnsubscription(true);
    };

    return (
        <>
            <div className="position-relative">
                <div className="container-fluid">
                    <div className="row margin-bottom-20">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            {isLoading ? (
                                <ContentLoader width="100%" height="100%" />
                            ) : (
                                <img src={getImageSrc()} alt={getImageAlt()} width="100%" />
                            )}
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <div className="padding-left-25 padding-right-25">
                                <h1>
                                    {isLoading ? (
                                        <ContentLoader />
                                    ) : (
                                        <FormattedMessage
                                            id={`notification-web.${route}.title`}
                                            values={{
                                                email: route === CONFIRM_UNSUBSCRIPTION && (
                                                    <FormattedMessage
                                                        id={`notification-web.email_type.${email_type.toLowerCase()}`}
                                                    />
                                                ),
                                            }}
                                        />
                                    )}
                                </h1>
                                <p className="padding-top-10">
                                    {isLoading ? (
                                        <ContentLoader />
                                    ) : (
                                        <FormattedMessage
                                            id={`notification-web.${route}.message`}
                                            values={{
                                                email: (route === ALREADY_UNSUBSCRIBED || route === RESUBSCRIBED) && (
                                                    <FormattedMessage
                                                        id={`notification-web.email_type.${email_type.toLowerCase()}`}
                                                    />
                                                ),
                                            }}
                                        />
                                    )}
                                </p>
                                {isFeedback && (
                                    <p className="padding-top-10">
                                        <FormattedMessage id="notification-web.feedback.message.2" />
                                    </p>
                                )}
                                {route === CONFIRM_UNSUBSCRIPTION && (
                                    <div className="display-flex text-color-white justify-content-center">
                                        <button
                                            className="btn btn-lg"
                                            style={{ backgroundColor: '#E40045' }}
                                            onClick={goToUnsubscribePage}
                                        >
                                            <b>
                                                <FormattedMessage
                                                    id={'notification-web.common.confirm_unsubscription'}
                                                />
                                            </b>
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default CommonPage;
