import axios from 'axios';
import { useEffect, useState } from 'react';
import { config } from '../../../config';
import { UpdateReason } from '../../../generated/UnsubscribeServiceTypes';
import { FEEDBACK, LINK_EXPIRED, PAGE_NOT_FOUND } from '../routes/routes';
import useCommonData from './useCommonData';

const useUnsubscribeFeedback = () => {
    const { setNavigation, dispatchLoadingChanged, location, unsubscribedInfo, setUnsubscribedInfo } = useCommonData();
    const [triggerReloadFeedback, setTriggerReloadFeedback] = useState<boolean>(false);
    const [updateReason, setUpdateReason] = useState<UpdateReason>();

    const triggerReload = (reason: UpdateReason) => {
        setUpdateReason(reason);
        setTriggerReloadFeedback(true);
    };

    useEffect(() => {
        if (!location.isLoading && triggerReloadFeedback && updateReason) {
            setTriggerReloadFeedback(false);
            dispatchLoadingChanged(true);
            axios
                .post(
                    config.backend.NOTIFICATION_SERVICE + '/unsubscribe/feedback?token=' + updateReason.token,
                    updateReason
                )
                .then((res) => {
                    setUnsubscribedInfo({
                        language: res.data.language,
                        email_type: res.data.email_type,
                        audience: res.data.audience,
                    });
                    if (res.status === 200) setNavigation(FEEDBACK);
                })
                .catch((error) => {
                    if (error.response?.status === 404) setNavigation(LINK_EXPIRED);
                    else setNavigation(PAGE_NOT_FOUND);
                });
        }
    }, [updateReason?.token, triggerReloadFeedback]);

    return { unsubscribedInfo, route: location.route, triggerReload };
};

export default useUnsubscribeFeedback;
