const Footer = () => (
    <div className="bg-lighter">
        <div className="padding-left-15">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xs-6 col-sm-4 col-md-3 col-lg-3">
                        <h6 className="padding-bottom-25 padding-top-25">Publisher</h6>
                        <span>MAN Truck & Bus SE</span> <br />
                        <span>Dachauer Str. 667</span> <br />
                        <span>80995 Munich</span> <br />
                        <span /> <br />
                        <span>
                            Tel.{' '}
                            <a
                                href="tel:+49891580"
                                style={{
                                    color: '#303C49',
                                    textDecoration: 'none',
                                }}
                            >
                                +49 (0)89 1580-0
                            </a>
                        </span>
                        <br />
                        <span>
                            Fax{' '}
                            <a
                                href="tel:+4989150391212"
                                style={{
                                    color: '#303C49',
                                    textDecoration: 'none',
                                }}
                            >
                                +49 (0)89 15039-1212
                            </a>
                        </span>
                    </div>

                    <div className="col-xs-6 col-sm-4 col-md-3 col-lg-3">
                        <h6 className="padding-bottom-25 padding-top-25">Members of the Board</h6>
                        <span>Alexander Vlaskamp</span> <br />
                        <span>Michael Kobriger</span> <br />
                        <span>Göran Nyberg</span> <br />
                        <span>Arne Puls</span> <br />
                        <span>Dr. Frederik Zohm</span> <br />
                        <span />
                        <br />
                    </div>

                    <div className="col-xs-6 col-sm-4 col-md-3 col-lg-3">
                        <h6 className="padding-bottom-25 padding-top-25">Social Media</h6>
                        <span>
                            <a
                                href="https://www.facebook.com/mantruckandbus"
                                className="social-link"
                                style={{
                                    color: '#303C49',
                                    textDecoration: 'none',
                                }}
                            >
                                <img
                                    src="https://email-assets-repository.s3.eu-west-1.amazonaws.com/icons/icon-fb%402x.png"
                                    width="26"
                                    alt="Facebook"
                                />
                                <span>MAN Truck & Bus</span>
                            </a>
                        </span>
                        <br />
                        <span>
                            <a
                                href="https://www.youtube.com/user/mantrucksandbuses"
                                className="social-link"
                                style={{
                                    color: '#303C49',
                                    textDecoration: 'none',
                                }}
                            >
                                <img
                                    src="https://email-assets-repository.s3.eu-west-1.amazonaws.com/icons/icon-yt%402x.png"
                                    width="26"
                                    alt="Youtube"
                                />
                                <span>MAN Truck & Bus</span>
                            </a>
                        </span>
                        <br />
                        <span>
                            <a
                                href="https://www.facebook.com/MAN.Truckers.World"
                                className="social-link"
                                style={{
                                    color: '#303C49',
                                    textDecoration: 'none',
                                }}
                            >
                                <img
                                    src="https://email-assets-repository.s3.eu-west-1.amazonaws.com/icons/icon-fb%402x.png"
                                    width="26"
                                    alt="Facebook"
                                />
                                <span>Trucker´s World by MAN</span>
                            </a>
                        </span>
                        <br />
                        <span>
                            <a
                                href="https://www.instagram.com/mantruckandbus/"
                                className="social-link"
                                style={{
                                    color: '#303C49',
                                    textDecoration: 'none',
                                }}
                            >
                                <img
                                    src="https://email-assets-repository.s3.eu-west-1.amazonaws.com/icons/icon-in%402x.png"
                                    width="26"
                                    alt="Instagram"
                                />
                                <span>MAN Truck & Bus</span>
                            </a>
                        </span>
                        <br />
                        <span>
                            <a
                                href="https://twitter.com/man_group"
                                className="social-link"
                                style={{
                                    color: '#303C49',
                                    textDecoration: 'none',
                                }}
                            >
                                <img
                                    src="https://email-assets-repository.s3.eu-west-1.amazonaws.com/icons/icon-tw%402x.png"
                                    width="26"
                                    alt="Twitter"
                                />
                                <span>MAN Truck & Bus</span>
                            </a>
                        </span>{' '}
                        <br />
                        <span>
                            <a
                                href="https://www.linkedin.com/company/man-commercial-vehicles/?originalSubdomain=de"
                                className="social-link"
                                style={{
                                    color: '#303C49',
                                    textDecoration: 'none',
                                }}
                            >
                                <img
                                    src="https://email-assets-repository.s3.eu-west-1.amazonaws.com/icons/icon-li%402x.png"
                                    width="26"
                                    alt="LinkedIn"
                                />
                                <span>MAN Truck & Bus SE</span>
                            </a>
                        </span>
                    </div>
                    <div className="col-xs-6 col-sm-4 col-md-3 col-lg-3">
                        <h6 className="padding-bottom-25 padding-top-25">Registry Court</h6>
                        <span>District court of Munich,</span> <br />
                        <span>HRB 86963</span>
                        <br />
                        <span>VAT No. DE 811125281</span>
                    </div>
                </div>

                <div className="row padding-bottom-25 padding-top-25">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div
                            style={{
                                textDecoration: 'none',
                                color: '#303C49',
                            }}
                        >
                            <a
                                href="https://www.mantruckandbus.com/en/meta/imprint.html"
                                style={{
                                    textDecoration: 'none',
                                    color: '#303C49',
                                    paddingRight: '30px',
                                }}
                            >
                                Imprint
                            </a>
                            <a
                                href="https://www.mantruckandbus.com/en/meta/data-protection.html"
                                style={{
                                    textDecoration: 'none',
                                    color: '#303C49',
                                }}
                            >
                                Data Protection
                            </a>
                        </div>
                    </div>
                </div>

                <div className="row padding-bottom-25">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <span>@ MAN 2022</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default Footer;
