import { Routes, Route } from 'react-router-dom';
import DefaultRedirect from './routes/DefaultRedirect';
import RouteUpdater from './routes/RouteUpdater';
import UnsubscribedSuccessfully from './components/pages/UnsubscribedSuccessfully';
import CommonPage from './components/pages/CommonPage';
import {
    ALREADY_UNSUBSCRIBED,
    CONFIRM_UNSUBSCRIPTION,
    FEEDBACK,
    LINK_EXPIRED,
    PAGE_NOT_FOUND,
    RESUBSCRIBED,
    SUCCESS,
} from './routes/routes';
import useUnsubscription from '../app/hooks/useUnsubscription';
import { useAppDispatch } from '../../configuration/setup/hooks';
import { localeChanged } from '../../configuration/lang/langSlice';
import { saveToken } from './appSlice';

const AppRoutes = ({ token }: { token?: string }) => {
    const dispatch = useAppDispatch();
    const dispatchLocaleChanged = (value: string) => dispatch(localeChanged(value));
    const dispatchSaveToken = (value: string) => dispatch(saveToken(value));
    if (token) dispatchSaveToken(token);
    const { unsubscribedInfo } = useUnsubscription(dispatchLocaleChanged);

    return (
        <>
            <Routes>
                <Route
                    path={SUCCESS}
                    element={<UnsubscribedSuccessfully unsubscribedInfo={unsubscribedInfo} token={token} />}
                />
                <Route path={ALREADY_UNSUBSCRIBED} element={<CommonPage unsubscribedInfo={unsubscribedInfo} />} />
                <Route path={LINK_EXPIRED} element={<CommonPage unsubscribedInfo={unsubscribedInfo} />} />
                <Route path={PAGE_NOT_FOUND} element={<CommonPage unsubscribedInfo={unsubscribedInfo} />} />
                <Route path={RESUBSCRIBED} element={<CommonPage unsubscribedInfo={unsubscribedInfo} />} />
                <Route path={FEEDBACK} element={<CommonPage unsubscribedInfo={unsubscribedInfo} />} />
                <Route path={CONFIRM_UNSUBSCRIPTION} element={<CommonPage unsubscribedInfo={unsubscribedInfo} />} />
                <Route path="*" element={<DefaultRedirect />} />
            </Routes>
            <RouteUpdater />
        </>
    );
};

export default AppRoutes;
