const AppHeader = () => {
    return (
        <div className="width-100pct height-50">
            <div>
                <a href="https://www.mantruckandbus.com">
                    <img
                        className="logo float-right"
                        src="https://email-assets-repository.s3.eu-west-1.amazonaws.com/images/man-logo-badge.png"
                        width="100"
                        height="50"
                    />
                </a>
            </div>
        </div>
    );
};

export default AppHeader;
