import { FormattedMessage, useIntl } from 'react-intl';
import Footer from '../common/Footer';
import SubHeader from '../common/SubHeader';
import CustomerUnsubscribedSuccessfullyImage from '../../../../images/CustomerUnsubscribedSuccessfully.png';
import WorkshopUnsubscribedSuccessfullyImage from '../../../../images/WorkshopUnsubscribedSuccessfully.png';
import { Reason, UnsubscribedInfo, UpdateReason } from '../../../../generated/UnsubscribeServiceTypes';
import RadioButton from '@rio-cloud/rio-uikit/lib/es/RadioButton';
import { useState } from 'react';
import useResubscription from '../../hooks/useResubscription';
import useUnsubscribeFeedback from '../../hooks/useUnsubscribeFeedback';

const UnsubscribedSuccessfully = ({
    unsubscribedInfo,
    token,
}: {
    unsubscribedInfo: UnsubscribedInfo;
    token?: string;
}) => {
    const { audience, email_type } = unsubscribedInfo;
    const { setTriggerReloadResubscription } = useResubscription(token);
    const { triggerReload } = useUnsubscribeFeedback();
    const intl = useIntl();
    const totalWordCount = 3000;
    const textAreaRows = 4;
    const [feedbackText, setFeedbackText] = useState<string>('');
    const [reason, setReason] = useState<Reason>('NO_LONGER_WANT_TO_RECEIVE');
    const [showOtherReason, setShowOtherReason] = useState<boolean>(false);
    const placeholderText = intl.formatMessage({ id: 'notification-web.unsubscribed.feedback.placeholder' });

    const getImageSrc = () => {
        switch (audience) {
            case 'CUSTOMER':
                return CustomerUnsubscribedSuccessfullyImage;
            case 'WORKSHOP':
                return WorkshopUnsubscribedSuccessfullyImage;
        }
    };

    const handleTextChange = (event: { target: { value: string } }) => {
        const value = event.target.value;
        setFeedbackText(value);
    };

    const selectUnsubscribeReason = (event: { target: { value: Reason } }) => {
        const value = event.target.value;
        if (value === 'OTHER_REASON') setShowOtherReason(true);
        setReason(value);
    };

    return (
        <>
            <div className="position-relative">
                <div>
                    <img src={getImageSrc()} alt={`${audience} Unsubscribed Successfully`} width="100%" />
                </div>
                <SubHeader emailType={email_type} />
            </div>
            <div className="padding-top-20 padding-bottom-20 padding-left-25 padding-right-25">
                <div className="margin-bottom-20">
                    <p>
                        <FormattedMessage
                            id={'notification-web.unsubscribed.message'}
                            values={{
                                email: (
                                    <FormattedMessage id={`notification-web.email_type.${email_type.toLowerCase()}`} />
                                ),
                            }}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'notification-web.unsubscribed.resubscribe'}
                            values={{
                                resubscribe_link: (
                                    <span
                                        className="text-color-secondary cursor-pointer"
                                        onClick={setTriggerReloadResubscription.bind(null, true)}
                                    >
                                        <FormattedMessage id="notification-web.unsubscribed.resubscribe_link" />
                                    </span>
                                ),
                            }}
                        />
                    </p>
                </div>

                <div className="padding-top-10">
                    <h4>
                        <FormattedMessage id="notification-web.unsubscribed.feedback.header" />
                    </h4>
                    <p>
                        <FormattedMessage id="notification-web.unsubscribed.feedback.sub_header" />
                    </p>
                    <div className="margin-bottom-10">
                        <RadioButton
                            name="radios"
                            id="optionsRadios1"
                            value="NO_LONGER_WANT_TO_RECEIVE"
                            tabIndex={1}
                            defaultChecked
                            onClick={selectUnsubscribeReason}
                        >
                            <FormattedMessage id="notification-web.unsubscribed.feedback.reason.1" />
                        </RadioButton>
                    </div>
                    <div className="margin-bottom-10">
                        <RadioButton
                            name="radios"
                            id="optionsRadios2"
                            data-testid="TOO_MANY_EMAILS_SAME_TOPIC"
                            value="TOO_MANY_EMAILS_SAME_TOPIC"
                            tabIndex={2}
                            onClick={selectUnsubscribeReason}
                        >
                            <FormattedMessage id="notification-web.unsubscribed.feedback.reason.2" />
                        </RadioButton>
                    </div>
                    <div className="margin-bottom-10">
                        <RadioButton
                            name="radios"
                            id="optionsRadios3"
                            value="CONTENT_NOT_RELEVANT"
                            tabIndex={3}
                            onClick={selectUnsubscribeReason}
                        >
                            <FormattedMessage id="notification-web.unsubscribed.feedback.reason.3" />
                        </RadioButton>
                    </div>
                    <div className="margin-bottom-10">
                        <RadioButton
                            name="radios"
                            id="optionsRadios4"
                            value="CONTENT_NOT_EXPECTED"
                            tabIndex={3}
                            onClick={selectUnsubscribeReason}
                        >
                            <FormattedMessage id="notification-web.unsubscribed.feedback.reason.4" />
                        </RadioButton>
                    </div>
                    <div className="margin-bottom-10">
                        <RadioButton
                            name="radios"
                            id="optionsRadios5"
                            data-testid="OTHER_REASON"
                            value="OTHER_REASON"
                            tabIndex={3}
                            onClick={selectUnsubscribeReason}
                        >
                            <FormattedMessage id="notification-web.unsubscribed.feedback.reason.5" />
                        </RadioButton>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                    {showOtherReason && (
                                        <div className="margin-top-10 margin-bottom-10">
                                            <textarea
                                                data-testid="COMMENT"
                                                className="form-control max-height-200"
                                                placeholder={placeholderText}
                                                value={feedbackText}
                                                rows={textAreaRows}
                                                maxLength={totalWordCount}
                                                onChange={handleTextChange}
                                            />
                                        </div>
                                    )}

                                    <div className="display-flex justify-content-end">
                                        <button
                                            className="btn btn-lg text-color-white text-align-right"
                                            style={{ backgroundColor: '#E40045' }}
                                            onClick={triggerReload.bind(null, {
                                                token,
                                                reason,
                                                comment: feedbackText,
                                            } as UpdateReason)}
                                        >
                                            <b>
                                                <FormattedMessage id={'notification-web.common.submit'} />
                                            </b>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default UnsubscribedSuccessfully;
