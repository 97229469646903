import { useEffect, useState } from 'react';

const useToken = () => {
    const [token, setToken] = useState<string | undefined>();

    useEffect(() => {
        if (!token) {
            const newToken = new URLSearchParams(window.location.search).get('token');
            if (newToken !== null) setToken(newToken);
        }
    }, [token]);

    return { token, setToken };
};

export default useToken;
