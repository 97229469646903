import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../configuration/setup/store';
import messagesBG from '../../features/translations/bg-BG.json';
import messagesCS from '../../features/translations/cs-CZ.json';
import messagesDA from '../../features/translations/da-DK.json';
import messagesDE from '../../features/translations/de-DE.json';
import messagesEL from '../../features/translations/el-GR.json';
import messagesEN from '../../features/translations/en-GB.json';
import messagesES from '../../features/translations/es-ES.json';
import messagesET from '../../features/translations/et-EE.json';
import messagesFI from '../../features/translations/fi-FI.json';
import messagesFR from '../../features/translations/fr-FR.json';
import messagesHR from '../../features/translations/hr-HR.json';
import messagesHU from '../../features/translations/hu-HU.json';
import messagesIT from '../../features/translations/it-IT.json';
import messagesKO from '../../features/translations/ko-KR.json';
import messagesLT from '../../features/translations/lt-LT.json';
import messagesLV from '../../features/translations/lv-LV.json';
import messagesNB from '../../features/translations/nb-NO.json';
import messagesNL from '../../features/translations/nl-NL.json';
import messagesPL from '../../features/translations/pl-PL.json';
import messagesPT from '../../features/translations/pt-PT.json';
import messagesRO from '../../features/translations/ro-RO.json';
import messagesSK from '../../features/translations/sk-SK.json';
import messagesSL from '../../features/translations/sl-SI.json';
import messagesSV from '../../features/translations/sv-SE.json';

import { DEFAULT_LOCALE, getSupportedLocale, supportedLocaleMap } from './lang';

export type DisplayMessages = Record<string, string>;

interface CombinedMessages {
    [key: string]: DisplayMessages;
}

export interface LangState {
    allMessages: CombinedMessages;
    displayMessages: DisplayMessages | undefined;
    displayLocale: string | undefined;
}

interface MessagesPayload {
    locale: string;
    displayMessages: DisplayMessages;
}

const defaultMessages = {
    [DEFAULT_LOCALE]: messagesEN,
    [supportedLocaleMap['bg']]: messagesBG,
    [supportedLocaleMap['cs']]: messagesCS,
    [supportedLocaleMap['da']]: messagesDA,
    [supportedLocaleMap['de']]: messagesDE,
    [supportedLocaleMap['el']]: messagesEL,
    [supportedLocaleMap['es']]: messagesES,
    [supportedLocaleMap['et']]: messagesET,
    [supportedLocaleMap['fi']]: messagesFI,
    [supportedLocaleMap['fr']]: messagesFR,
    [supportedLocaleMap['hr']]: messagesHR,
    [supportedLocaleMap['hu']]: messagesHU,
    [supportedLocaleMap['it']]: messagesIT,
    [supportedLocaleMap['ko']]: messagesKO,
    [supportedLocaleMap['lt']]: messagesLT,
    [supportedLocaleMap['lv']]: messagesLV,
    [supportedLocaleMap['nb']]: messagesNB,
    [supportedLocaleMap['nl']]: messagesNL,
    [supportedLocaleMap['pl']]: messagesPL,
    [supportedLocaleMap['pt']]: messagesPT,
    [supportedLocaleMap['ro']]: messagesRO,
    [supportedLocaleMap['sk']]: messagesSK,
    [supportedLocaleMap['sl']]: messagesSL,
    [supportedLocaleMap['sv']]: messagesSV,
};

const initialState: LangState = {
    allMessages: defaultMessages,
    displayMessages: undefined,
    displayLocale: undefined,
};

const langSlice = createSlice({
    name: 'lang',
    initialState,
    reducers: {
        localeChanged: (state, action: PayloadAction<string>) => {
            const preferredLocale = action.payload;
            const displayLocale = getSupportedLocale(preferredLocale);

            state.displayLocale = displayLocale;
            state.displayMessages = state.allMessages[displayLocale];
        },
        displayMessagesFetched: (state, action: PayloadAction<MessagesPayload>) => {
            const { locale, displayMessages } = action.payload;

            state.allMessages = {
                ...state.allMessages,
                [locale]: displayMessages,
            };
            state.displayMessages = displayMessages;
            state.displayLocale = locale;
        },
    },
});

export const { localeChanged, displayMessagesFetched } = langSlice.actions;

export const getLocale = (state: RootState) => state.lang.displayLocale;
export const getDisplayMessages = (state: RootState) => state.lang.displayMessages;

export default langSlice.reducer;
