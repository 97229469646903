import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../../configuration/setup/store';

export interface AppState {
    sessionExpiredAcknowledged: boolean;
    token: string | undefined;
}

const initialState: AppState = {
    sessionExpiredAcknowledged: false,
    token: undefined,
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        hideSessionExpiredDialog: (state: AppState) => {
            state.sessionExpiredAcknowledged = true;
        },
        saveToken: (state: AppState, action: PayloadAction<string>) => {
            state.token = action.payload;
        },
    },
});

export const { hideSessionExpiredDialog, saveToken } = appSlice.actions;
export const getToken = (state: RootState) => state.app.token;

export default appSlice.reducer;
