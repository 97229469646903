import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LOADING } from '../../features/app/routes/routes';
import { RootState } from './store';

const initialState = {
    route: LOADING,
    isLoading: false,
};

const locationSlice = createSlice({
    name: 'location',
    initialState,
    reducers: {
        routeChanged: (state, action: PayloadAction<string>) => {
            state.route = action.payload;
        },
        loadingChanged: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
    },
});

export const { routeChanged, loadingChanged } = locationSlice.actions;

export const getLocation = (state: RootState) => state.location;

export default locationSlice.reducer;
